<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                $t("PRODUCT.NOT_FOUND")
            }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{
                            edit_mode
                                ? add_new
                                    ? $t("PRODUCT.NEW")
                                    : $t("PRODUCT.EDIT")
                                : $t("PRODUCT.VIEW")
                        }}
                    </h3>
                    <span
                        class="text-muted font-weight-bold font-size-sm mt-1"
                        >{{ product.name }}</span
                    >
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                        v-on:click="save()"
                        class="btn btn-primary px-15 mr-2"
                        id="product_save"
                        >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                        >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                        >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="product_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("PRODUCT.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="name"
                                        name="name"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("PRODUCT.BRAND_DIVISION") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="brand_division"
                                        name="brand_division"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.brand_division"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("PRODUCT.SKU") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="sku"
                                        name="sku"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.sku"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("PRODUCT.CATEGORY") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="category"
                                        name="category"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.category"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("PRODUCT.SUB_CATEGORY") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="sub_category"
                                        name="sub_category"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.sub_category"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{
                                        $t("PRODUCT.FURTHER_SUB_CATEGORY")
                                    }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="further_sub_category"
                                        name="further_sub_category"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="
                                            product.further_sub_category
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("PRODUCT.GENDER") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="gender"
                                        name="gender"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.gender"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("PRODUCT.FEDAS_PCK") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="fedas_pck"
                                        name="fedas_pck"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.fedas_pck"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{ $t("PRODUCT.APP_DESCRIPTION") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <textarea
                                        rows="1"
                                        ref="description_1"
                                        name="description_1"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.description_1"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{
                                        $t("PRODUCT.INTERNAL_DESCRIPTION")
                                    }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <textarea
                                        rows="1"
                                        ref="description_2"
                                        name="description_2"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.description_2"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    >{{
                                        $t(
                                            "PRODUCT.FURTHER_INTERNAL_DESCRIPTION"
                                        )
                                    }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <textarea
                                        rows="1"
                                        ref="description_3"
                                        name="description_3"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-bind:value="product.description_3"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
        <div v-if="!add_new && !not_found" class="mt-5">
            <Colors></Colors>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Colors from "@/view/pages/Colors"
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger"
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap"
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core"
import KTUtil from "@/assets/js/components/util"

export default {
    name: "product",
    data() {
        return {
            add_new: false,
            edit_mode: false,
            not_found: false,
            product: {
                name: "",
                sku: "",
                gender: "",
                fedas_pck: "",
                category: "",
                sub_category: "",
                further_sub_category: "",
                brand_division: "",
                description_1: "",
                description_2: "",
                description_3: "",
            },
        }
    },
    mounted() {
        if (this.$route.params.id == "new") {
            this.add_new = true
            this.edit_mode = true
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: this.$t("PRODUCT.NEW") },
            ])
        } else {
            ApiService.get("product/" + this.$route.params.id)
                .then((data) => {
                    this.product = data.data
                    this.$store.dispatch(SET_BREADCRUMB, [
                        { title: data.data.name },
                    ])
                    if (this.$route.params.edit) {
                        this.edit_mode = true
                    }
                })
                .catch(() => {
                    this.not_found = true
                    this.$store.dispatch(SET_BREADCRUMB, [])
                })
        }

        this.fv = formValidation(KTUtil.getById("product_form"), {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("PRODUCT.NAME"),
                            }),
                        },
                    },
                },
                sku: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: this.$t("PRODUCT.SKU"),
                            }),
                        },
                        regexp: {
                            regexp: /^[\x00-\x7F]+$/,
                            message: this.$t("VALIDATION.ASCII", {
                                name: this.$t("PRODUCT.SKU"),
                            }),
                        },
                        blank: {
                            message: this.$t("VALIDATION.EXISTS", {
                                name: this.$t("PRODUCT.SKU"),
                            }),
                        },
                    },
                },
                fedas_pck: {
                    validators: {
                        digits: {
                            message: this.$t("VALIDATION.DIGITS", {
                                name: this.$t("PRODUCT.FEDAS_PCK"),
                            }),
                        },
                        stringLength: {
                            message: this.$t("VALIDATION.LENGTH_EXACT", {
                                name: this.$t("PRODUCT.FEDAS_PCK"),
                                length: 6,
                            }),
                            min: 6,
                            max: 6,
                        },
                    },
                },
                description_1: {
                    validators: {
                        stringLength: {
                            message: this.$t("VALIDATION.LENGTH_MAX", {
                                name: this.$t("PRODUCT.APP_DESCRIPTION"),
                                length: 255,
                            }),
                            max: 255,
                        },
                    },
                },
                description_2: {
                    validators: {
                        stringLength: {
                            message: this.$t("VALIDATION.LENGTH_MAX", {
                                name: this.$t("PRODUCT.INTERNAL_DESCRIPTION"),
                                length: 255,
                            }),
                            max: 255,
                        },
                    },
                },
                description_3: {
                    validators: {
                        stringLength: {
                            message: this.$t("VALIDATION.LENGTH_MAX", {
                                name: this.$t(
                                    "PRODUCT.FURTHER_INTERNAL_DESCRIPTION"
                                ),
                                length: 255,
                            }),
                            max: 255,
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        })
    },
    methods: {
        edit() {
            this.edit_mode = true
        },
        cancel() {
            if (this.add_new) {
                this.$router.push({ name: "products" })
            } else {
                this.fv.resetForm(true)
                this.edit_mode = false
            }
        },
        save() {
            this.fv.validate().then((status) => {
                if (status == "Valid") {
                    //console.log(this.$refs)
                    var edited_product = {
                        name: this.$refs.name.value,
                        brand_division: this.$refs.brand_division.value,
                        sku: this.$refs.sku.value,
                        category: this.$refs.category.value,
                        sub_category: this.$refs.sub_category.value,
                        further_sub_category:
                            this.$refs.further_sub_category.value,
                        gender: this.$refs.gender.value,
                        fedas_pck: this.$refs.fedas_pck.value,
                        description_1: this.$refs.description_1.value,
                        description_2: this.$refs.description_2.value,
                        description_3: this.$refs.description_3.value,
                    }
                    KTUtil.getById("product_save").classList.add(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    )

                    if (this.add_new) {
                        ApiService.post("product", edited_product)
                            .then((data) => {
                                KTUtil.getById("product_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.$router.push({
                                    name: "product",
                                    params: { id: data.data.id },
                                })
                            })
                            .catch((error) => {
                                if (error.response.status == 422) {
                                    error.response.data.errors.forEach(
                                        (error) => {
                                            if (error.param === "sku") {
                                                this.fv.updateFieldStatus(
                                                    "sku",
                                                    "Invalid",
                                                    "blank"
                                                )
                                            }
                                        }
                                    )
                                }
                                KTUtil.getById("product_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    } else {
                        ApiService.put(
                            "product/" + this.product.id,
                            edited_product
                        )
                            .then((data) => {
                                KTUtil.getById("product_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                                this.product = data.data
                                this.cancel()
                            })
                            .catch((error) => {
                                if (error.response.status == 422) {
                                    error.response.data.errors.forEach(
                                        (error) => {
                                            if (error.param === "sku") {
                                                this.fv.updateFieldStatus(
                                                    "sku",
                                                    "Invalid",
                                                    "blank"
                                                )
                                            }
                                        }
                                    )
                                }
                                KTUtil.getById("product_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    }
                }
            })
        },
    },
    components: {
        Colors,
    },
}
</script>
