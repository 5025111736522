<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        alert.text
      }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("COLOR.COLORS")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("COLOR.SHOW_COUNT", paginatedColors.length, {
              total: colors.length,
            })
          }}</span>
                </h3>
                <div class="card-toolbar">
                    <router-link
                            :to="{
              name: 'color',
              params: { id: 'new', product_id: this.$route.params.id },
            }"
                    >
                        <b-button variant="primary">{{ $t("COLOR.NEW") }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="colors.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 80px">
                                {{ $t("COLOR.IMAGE") }}
                            </th>
                            <th style="min-width: 140px">{{ $t("COLOR.NAME") }}</th>
                            <!--COLORCODE-->
                            <th style="min-width: 80px">{{ $t("COLOR.CODE") }}</th>
                            <th style="min-width: 100px">{{ $t("COLOR.WEIGHT") }}</th>
                            <th style="min-width: 80px">{{ $t("COLOR.YEAR") }}</th>
                            <th style="min-width: 110px">{{ $t("COLOR.ORIGIN") }}</th>
                            <th style="min-width: 100px">{{ $t("COLOR.URL") }}</th>
                             <th style="min-width: 60px">{{ $t("COLOR.TAGS") }}</th>
                              <th style="min-width: 60px">{{ $t("COLOR.SCANS") }}</th>
                            <th class="pr-0 text-right" style="min-width: 110px">
                                {{ $t("ACTIONS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="color in paginatedColors">
                            <tr v-bind:key="color.id">
                                <td class="pl-0">
                                    <div v-if="color.image" class="symbol">
                                        <img
                                                :src="color.image"
                                                style="
                          width: auto;
                          height: auto;
                          max-width: 50px;
                          max-height: 50px;
                        "
                                        />
                                    </div>
                                    <span
                                            v-else
                                            class="label label-lg label-inline label-light-danger"
                                    >{{ $t("COLOR.NO_IMAGE") }}</span
                                    >
                                </td>
                                <td>
                                    <router-link
                                            :to="{ name: 'color', params: { id: color.id } }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ color.name }}
                                    </router-link
                                    >
                                </td>
                                <td>
                                    <router-link
                                            :to="{ name: 'color', params: { id: color.id } }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ color.code }}
                                    </router-link
                                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ color.weight ? color.weight + " g" : null }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ color.year }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ color.origin }}</span
                    >
                                </td>
                                <td>
                                    <a
                                            v-bind:href="color.url"
                                            rel="noopener"
                                            target="_blank"
                                            class="text-dark-75 font-weight-bold text-hover-primary font-size-lg"
                                    >{{ color.url }}</a
                                    >
                                </td>
                                <td>
                                    <a
                                                                            v-bind:href="color.tags"
                                                                            rel="noopener"
                                                                            target="_blank"
                                                                            class="text-dark-75 font-weight-bold text-hover-primary font-size-lg"
                                                                    >{{ color.tags }}</a
                                                                    >
                                                                </td>
                                 <td>
                                  <a
                                        v-bind:href="color.scans"
                                             rel="noopener"
                                         target="_blank"
                                        class="text-dark-75 font-weight-bold text-hover-primary font-size-lg"
                                                                                                    >{{ color.scans }}</a
                                                                                                    >
                                                                                                </td>
                                <td class="pr-0 text-right">
                                    <router-link
                                            :to="{
                        name: 'color',
                        params: { id: color.id, edit: true },
                      }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <inline-svg
                                src="media/svg/icons/Communication/Write.svg"
                                :title="$t('EDIT')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </router-link>
                                    <a
                                            v-on:click="confirmDelete(color.id)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                                :title="$t('DELETE')"
                        ></inline-svg>
                          <!--end::Svg Icon-->
                      </span>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="colors.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="colors.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import Swal from "sweetalert2";

    export default {
        name: "colors",
        data() {
            return {
                colors: [],
                alert: {
                    show: false,
                    message: "",
                    variant: "",
                },
                pagination: {
                    current_page: 1,
                    per_page: 10,
                },
            };
        },
        mounted() {
            if (this.$route.params.id == "new") {
                return;
            }
            ApiService.get("product/" + this.$route.params.id + "/colors")
                .then(({data}) => {
                    this.colors = data;
                })
                .catch(() => {
                    //Just keep it empty
                });
        },
        methods: {
            confirmDelete: function (id) {
                const color_index = this.colors.findIndex((color) => color.id === id);
                const color = this.colors.find((color) => color.id === id);
                Swal.fire({
                    title: this.$t("COLOR.DELETE_TITLE", {name: color.name}),
                    text: this.$t("COLOR.DELETE_TEXT"),
                    icon: "error",
                    showCancelButton: true,
                    cancelButtonText: this.$t("CANCEL"),
                    confirmButtonText: this.$t("DELETE"),
                    buttonsStyling: false,
                    customClass: {
                        cancelButton: "btn btn-secondary",
                        confirmButton: "btn btn-danger",
                    },
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ApiService.delete("color/" + id)
                            .then(() => {
                                this.colors.splice(color_index, 1);
                                this.showAlert(
                                    this.$t("COLOR.DELETE_SUCCESS", {name: color.name}),
                                    "success"
                                );
                            })
                            .catch(() => {
                                this.showAlert(
                                    this.$t("COLOR.DELETE_FAILURE", {name: color.name}),
                                    "danger"
                                );
                            });
                    }
                });
            },
            showAlert(text, variant) {
                this.alert.text = text;
                this.alert.variant = variant;
                this.alert.show = true;
                setTimeout(() => (this.alert.show = false), 5000);
            },
        },
        computed: {
            paginatedColors() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.colors.slice(start, end);
            },
        },
    };
</script>
